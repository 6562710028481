/* Contenedor general de la navbar */
.navbar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 250px;
    background-color: #ffffff;
    padding-top: 20px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;
    z-index: 1000;
  }
  
  .navbar.closed {
    width: 80px; /* Solo muestra los iconos cuando está cerrada */
  }
  
  /* Contenido de la navbar con margen superior fijo */
  .navbar-content ul {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    position: absolute; /* Hacer que la lista de navegación esté posicionada */
    top: 20%; /* Aplicar el margen superior del 30% */
  }
  
  .navbar-content li {
    width: 100%;
    margin: 0;
  }
  
  .navbar-content .nav-link {
    color: #001C5A;
    text-decoration: none;
    font-size: 1.2rem;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    transition: background-color 0.3s ease, padding 0.3s ease;
  }
  
  .navbar-content .nav-link:hover {
    background-color: #001C5A;
    color: white;
  }
  
  .navbar-content .nav-link i {
    margin-right: 15px;
  }
  
  /* Botón de toggle (hamburger menu) */
  .navbar-toggle {
    background-color: white;
    color: #00236F;
    border: 2px solid #00236F;
    border-radius: 30%;
    padding: 10px;
    cursor: pointer;
    font-size: 1.5rem;
    z-index: 1001;
    position: fixed; /* Fijo fuera de la navbar */
    top: 20px;
    left: 20px; /* Posición fija fuera de la Navbar */
  }
  
  .navbar-toggle:hover {
    background-color: white;
  }
  
  /* Responsividad */
  @media (max-width: 767px) {
    .navbar {
      transform: translateX(-100%);
    }
  
    .navbar.open {
      transform: translateX(0);
    }
  
    .navbar.closed {
      transform: translateX(-100%);
    }
  
    .navbar-toggle {
      position: fixed;
      top: 20px;
      left: 10px; /* Ajustar la posición para móviles */
    }
  }
/* Contenedor general de la navbar */
.navbar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 250px;
    background-color: #ffffff;
    padding-top: 20px;
    box-shadow: 8px 0 5px rgba(0, 0, 0, 0.171);
    transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;
    z-index: 1000;
  }
  
  .navbar.closed {
    width: 80px; /* Solo muestra los iconos cuando está cerrada */
  }
  
  /* Contenido de la navbar con margen superior fijo */
  .navbar-content ul {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    position: absolute; /* Hacer que la lista de navegación esté posicionada */
    top: 20%; /* Aplicar el margen superior del 30% */
  }
  
  .navbar-content li {
    width: 100%;
    margin: 0;
  }
  
  .navbar-content .nav-link {
    color: #001C5A;
    text-decoration: none;
    font-size: 1.2rem;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    transition: background-color 0.3s ease, padding 0.3s ease;
  }
  
  .navbar-content .nav-link:hover {
    background-color: #001C5A;
    color: white;
  }
  
  .navbar-content .nav-link i {
    margin-right: 15px;
  }
  
  /* Botón de toggle (hamburger menu) */
  .navbar-toggle {
    background-color: white;
    color: #00236F;
    border: 1px solid #00236F;
    border-radius: 30%;
    padding: 10px;
    cursor: pointer;
    font-size: 1.5rem;
    z-index: 1001;
    position: fixed; /* Fijo fuera de la navbar */
    top: 20px;
    left: 20px; /* Posición fija fuera de la Navbar */
  }
  
  .navbar-toggle:hover {
    background-color: white;
  }
  
  /* Responsividad */
  @media (max-width: 767px) {
    .navbar {
      transform: translateX(-100%);
    }
  
    .navbar.open {
      transform: translateX(0);
    }
  
    .navbar.closed {
      transform: translateX(-100%);
    }
  
    .navbar-toggle {
      position: fixed;
      top: 20px;
      left: 10px; /* Ajustar la posición para móviles */
    }
  }
    

  /* Estilo para el contenedor principal de la página */
.main-content {
  transition: margin-left 0.3s ease-in-out;
  padding: 20px;
}

.navbar.closed ~ .main-content {
  margin-left: 80px; /* Cambia el margen cuando la navbar esté cerrada */
}

@media (max-width: 767px) {
  .main-content {
    margin-left: 0; /* Elimina el margen en móviles */
    padding: 10px;
  }

  .navbar.closed ~ .main-content {
    margin-left: 0; /* Sin margen cuando la navbar está cerrada en móviles */
  }
}
