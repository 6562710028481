

.table-container {
    width: 100%;
    overflow-x: auto;
    display: flex;
        flex-direction: column;
        align-items: center;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-family: Arial, sans-serif;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

th, td {
    padding: 12px 15px;
    text-align: center;
    border: 1px solid #ddd;
}

thead {
    background-color: #00297a;
    color: white;
}

tbody tr:nth-child(even) {
    background-color: #f9f9f9;
}

tbody tr:hover {
    background-color: #f1f1f1;
}

input[type="checkbox"] {
    transform: scale(1.2);
    cursor: pointer;
}


.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.pagination button {
    background: #00297a;
    color: white;
    border: none;
    padding: 8px 12px;
    margin: 0 5px;
    cursor: pointer;
    border-radius: 4px;
    transition: background 0.3s;
}

.pagination button:hover {
    background: #45a049;
}

.pagination button.active {
    background: #388E3C;
    font-weight: bold;
}

/* ---------- Card view en móvil ---------- */
@media screen and (max-width: 768px) {
    body {
        width: 350px !important;
    }
    table, thead, tbody, th, td, tr {
        width: 350px;
        flex-direction: column;
        align-items: center;
        display: block;
        margin-bottom: 20px;        
    }

    thead {
        display: none;
    }

    tr {
        margin-bottom: 20px; /* Aquí aumentamos la separación */
        box-shadow: 0 2px 6px rgba(0,0,0,0.1);
        border-radius: 8px;
        overflow: hidden;
        background: #fff;
    }

    td {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px;
        border: none;
        border-bottom: 1px solid #eee;
        flex-direction: column;
    }

    td:last-child {
        border-bottom: none;
    }

    td::before {
        content: attr(data-label);
        font-weight: bold;
        color: #00297a;
        flex: 1;
    }
}


.filters {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    flex-wrap: wrap;
    justify-content: center;
    background: #00297a;
    padding: 15px;
    border-radius: 8px;
}

.filter-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.filter-item label {
    font-weight: bold;
    margin-bottom: 5px;
    color: white;
}

.filter-item input,
.filter-item select {
    padding: 8px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-family: Arial, sans-serif;
    width: 200px;
}

.filter-item input:focus,
.filter-item select:focus {
    border-color: #45a049;
    outline: none;
}

/* Responsive filters */
@media screen and (max-width: 768px) {
    .filters {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .filter-item {
        width: 100%;
        max-width: 300px;
        margin-bottom: 10px;
        display: flex
        ;
                align-items: center;
    }

    .filter-item input,
    .filter-item select {
        width: 100%;
    }
}


.export-btn {
    background-color: #007bff;
    color: white;
    padding: 0.5rem 1rem;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 1.5rem;
}

.export-btn:hover {
    background-color: #0056b3;
}
