/* General body styling */
body, html {
    height: 100%;
    margin: 0;
    font-family: 'Arial', sans-serif;
    background-color: #f4f6f9;
    display: flex;
    justify-content: center;
  }
  
  /* Contenedor principal del login centrado */
  .login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  
  /* Caja de login */
  .login-box {
    background-color: white;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
    text-align: center;
  }
  
  /* Logo */
  .login-logo {
    max-width: 100px;
    margin-bottom: 20px;
  }
  
  /* Input fields */
  .form-control {
    border-radius: 5px;
    border: 1px solid #ced4da;
    padding: 0.75rem;
    font-size: 1rem;
    margin-bottom: 1rem;
    transition: border-color 0.2s;
  }
  
  .form-control:focus {
    border-color: #00236F;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 35, 111, 0.5);
  }
  
  /* Botón de acceso */
  .btn-primary {
    background-color: #00236F;
    border-color: #00236F;
    padding: 0.75rem;
    font-size: 1rem;
    width: 100%;
    border-radius: 5px;
    transition: background-color 0.2s, box-shadow 0.2s;
  }
  
  .btn-primary:hover {
    background-color: #001c5a;
    border-color: #001c5a;
    box-shadow: 0 0 10px rgba(0, 35, 111, 0.3);
  }
  
  /* Estilo del h2 (Iniciar Sesión) */
  h2 {
    color: #00236F;
    margin-bottom: 1.5rem;
    font-size: 1.75rem;
    font-weight: bold;
  }
  
  /* Responsivo para pantallas pequeñas */
  @media (max-width: 576px) {
    .login-box {
      padding: 1.5rem;
      margin: 1rem;
    }
  
    .login-logo {
      max-width: 80px;
    }
  
    h2 {
      font-size: 1.5rem;
    }
  
    .btn-primary {
      padding: 0.5rem;
      font-size: 0.875rem;
    }
  }
  